<mat-card class="relative fx-fill fx-card">
    <mat-toolbar
            color="primary"
            class="fx-card-toolbar fx-layout-row-nowrap fx-content-end fx-items-center fx-gap-8">

        <span class="font-semibold text-2xl mr-4">{{title}}</span>

        <span class="fx-grow-1"></span>

        @if (patientService.patientSelected) {
            <span>{{ patientService.patientSelected | async }}</span>
        }

        <span class="fx-grow-1"></span>

        @if ((patientService.patientSelected | async) === null) {

            @if (patientService.hasSelectedPatients() &&
            (profile?.mergePatient !== 'NONE' || profile?.patientModule)) {
                <button color="warn"
                        mat-raised-button
                        (click)="mergePatients()"
                >
                    <mat-icon
                            fontIcon="mdi-merge"
                            fontSet="mdi"
                    ></mat-icon>
                    {{'MERGE' | translate}}
                </button>
            }

            @if (profile?.exportPatient !== 'NONE' || profile?.patientModule) {
                <button
                        mat-raised-button
                        (click)="exportCSV()"
                        id="save"
                >
                    <mat-icon
                            fontIcon="mdi-cloud-download"
                            fontSet="mdi"
                    ></mat-icon>
                    {{'EXPORTCSV' | translate}}
                </button>
            }


            @if (profile?.createPatient !== 'NONE') {
                <button
                        (click)="createNewPatient()"
                        [matTooltip]="'ADD' | translate"
                        color="primary"
                        mat-raised-button>
                    <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                    {{ 'ADD' | translate }}
                </button>
            }
        }
    </mat-toolbar>

    <div class="relative fx-fill-height">
        <router-outlet></router-outlet>
    </div>
</mat-card>

import {
    AfterViewInit,
    Component, DestroyRef,
    inject,
    OnInit, TemplateRef, viewChild,
    ViewChild,
} from '@angular/core';
import {PatientService} from '../patient.service';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';

import {findIndex, get, map as _map, sortBy} from 'lodash';
import {
    BehaviorSubject,
    merge,
    of as observableOf,
} from 'rxjs';
import {PAT_TABLE_CONF} from './table-conf';
import {catchError, filter, map, switchMap} from 'rxjs/operators';
import {
    DeleteConfirmComponent,
    getConfidentialityColor,
    SharedService,
} from '../../shared';
import {rowsAnimation} from '../../animations';
import {AppConfigService} from '../../app-config.service';
import moment from 'moment';
import FileSaver from 'file-saver';
import {SelectionModel} from '@angular/cdk/collections';
import {SelectDialogComponent} from '../../shared/select-dialog/select-dialog.component';
import {DEFAULT_SEARCH_CONFIG, PatientDTO} from '../../model';
import {FormBuilder, FormGroup} from "@angular/forms";
import {SearchService} from "../../shared/advanced-search/search.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
    selector: 'ft-patient-list',
    templateUrl: './patient-list.component.html',
    styleUrls: ['./patient-list.component.scss'],
    animations: [rowsAnimation],
})
export class PatientListComponent implements AfterViewInit, OnInit {
    selection = new SelectionModel<PatientDTO>(true, []);

    title = 'PATIENTS';
    profile: any;
    canViewConfData: boolean;

    displayedColumns = [];
    columnsToDisplay = [];
    availableColumns = [];

    dataSource = new MatTableDataSource<PatientDTO>();

    filterForm: FormGroup;

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    filterChange = new BehaviorSubject('');
    resultsLength = 0;
    isLoadingResults = true;
    isResultsLoaded = false;

    deleteSubject = new BehaviorSubject<number>(null);
    dateFormat: string = 'DD/MM/YYYY';
    downloading: boolean;
    public titles: any = {};
    public genders: any = {};
    public maritalStatuses: any = {};
    public confidentialities: any = {};

    searchTemplate = viewChild.required<TemplateRef<any>>('searchTemplate');

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() {
        if (this.isAllSelected()) {
            this.selection.clear();
            this.patientService.hasSelectedPatients.set(false);
            return;
        }
        this.selection.select(...this.dataSource.data);
        this.patientService.hasSelectedPatients.set(this.selection.hasValue() && this.selection.selected.length > 1);
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;

    }

    private _destroyRef = inject(DestroyRef);

    constructor(
        private patientService: PatientService,
        private router: Router,
        private route: ActivatedRoute,
        private _config: AppConfigService,
        private dialog: MatDialog,
        private _fb: FormBuilder,
        private _shared: SharedService,
        private _searchService: SearchService
    ) {
        this._destroyRef.onDestroy(() => this._searchService.searchInputConfig.set(DEFAULT_SEARCH_CONFIG));

        setTimeout(() => {
            this._searchService.searchInputConfig.set({
                placeholder: 'search.patient',
                expandable: false,
                hidden: false,
                template: this.searchTemplate()
            });

            this._searchService.genericSearchObs.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(value => this.filterForm.get('key').patchValue(value));
        });

        this.filterForm = this._fb.group({key: ''});
        this.dateFormat = this._config.momentDateFormat;

        const user = get(this.route.snapshot.data, 'user');
        this.profile = get(user, 'profile');
        this.canViewConfData = user['canViewConfidentialData'];

        this.displayedColumns = PAT_TABLE_CONF.filter(it => !it.hidden);
        this.availableColumns = sortBy(
            PAT_TABLE_CONF.filter(item => !item.hidden && item.required),
            'order'
        );
        this.setColumnsToDisplay(this.availableColumns);
    }

    trackById(index: number, item: any): string {
        return item.id;
    }

    exportCSV() {
        this.downloading = true;
        let ids = 'all';
        if (this.filterForm.get('key').value != '')
            ids =
                this.dataSource.data.map(value => value['id']).join('.') ||
                'all';

        if (this.selection.hasValue())
            ids = this.selection.selected.map(it => it.id).join('.');

        this.patientService.exportPatients(ids)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(
                value => {
                    this.downloading = false;
                    const blob = new Blob([value], {
                        type: 'application/vnd.ms-excel',
                    });
                    const file = new File([blob], 'patients.xlsx', {
                        type: 'application/vnd.ms-excel',
                    });

                    FileSaver.saveAs(file);
                },
                _ => (this.downloading = false)
            );
    }

    toggleColumn(col) {
        const idx = findIndex(this.availableColumns, {header: col.header});
        this.availableColumns[idx].hidden = !col.hidden;
        this.setColumnsToDisplay(this.availableColumns);
    }

    setColumnsToDisplay(columns) {
        this.columnsToDisplay = _map(
            columns.filter(c => !c.hidden),
            'label'
        );
        this.columnsToDisplay.push('action');

        this.columnsToDisplay = ['select', ...this.columnsToDisplay];
    }

    showConfData(row: any, label: string): string {
        return !row.confidential || this.canViewConfData ? row[label] : '****';
    }

    editPatient(row: any) {
        this.router.navigate(['/patients/folder', row.id]);
    }

    deletePatient(row: any) {
        this.dialog
            .open(DeleteConfirmComponent, {data: 'PATIENT_DELETE_MESSAGE'})
            .afterClosed()
            .pipe(
                filter(ok => !!ok),
                switchMap(() => this.patientService.deletePatient(row.id)),
                takeUntilDestroyed(this._destroyRef)
            ).subscribe(() => this.deleteSubject.next(row.id));
    }

    ngOnInit() {
        this._shared
            .getDatasets('titles,confidentialities,genders,maritalStatuses')
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(data => {
                const {titles, genders, confidentialities, maritalStatuses} =
                    data;
                titles.forEach(it => (this.titles[it.id] = it.value));
                genders.forEach(it => (this.genders[it.id] = it.value));
                confidentialities.forEach(
                    it => (this.confidentialities[it.id] = it.value)
                );
                maritalStatuses.forEach(
                    it => (this.maritalStatuses[it.id] = it.description)
                );
            });
    }

    private resetPaginator() {
        this.patientService.patientCreated.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(
            () => (this.paginator.pageIndex = 0)
        );
    }

    ngAfterViewInit() {

        this.patientService.exportTriggered.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(_ => this.exportCSV());
        this.patientService.mergeTriggered.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(_ => this.mergePatients());

        this.resetPaginator();
        this.filterForm.get('key').valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(
            value => {
                if (!this.dataSource) return;
                this.paginator.pageIndex = 0;
                this.filterChange.next(value);
            }
        )


        this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

        const dataChanges = [
            this.sort.sortChange.asObservable(),
            this.paginator.page.asObservable(),
            this.filterChange.asObservable(),
            this.patientService.patientCreated,
            this.deleteSubject,
        ];

        merge(...dataChanges)
            .pipe(
                switchMap(() => {
                    this.isLoadingResults = true;
                    return this.patientService.getPatients(
                        this.paginator.pageSize,
                        this.paginator.pageIndex,
                        this.sort.active,
                        this.sort.direction,
                        this.filterChange.getValue()
                    );
                }),
                map(data => {
                    this.isLoadingResults = false;
                    this.isResultsLoaded = false;
                    this.resultsLength = data.totalElements;

                    return data.content;
                }),
                catchError(() => {
                    this.isLoadingResults = false;
                    this.isResultsLoaded = true;
                    return observableOf([]);
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(data => (this.dataSource.data = data));
    }

    getConfidentialityColor(conf: string): string {
        return getConfidentialityColor(conf);
    }

    can(row: any, action: string): boolean {
        return (
            (this.profile[action] !== 'NONE' && !row.confidential) ||
            this.canViewConfData
        );
    }

    formatDate(date: any): any {
        return date ? moment(date, 'YYYY-MM-DD').format(this.dateFormat) : '-';
    }

    mergePatients() {
        this.dialog
            .open(SelectDialogComponent, {
                minWidth: '360px',
                data: {items: this.selection.selected, entity: 'patient'},
            })
            .afterClosed()
            .pipe(
                filter(value => !!value),
                map(value => ({
                    ids: this.selection.selected
                        .map(it => it.externalPatientID)
                        .filter(it => it !== value.externalPatientID)
                        .join('_'),
                    patientID: value.externalPatientID
                })),
                switchMap(value => this.patientService.mergePatients(value.patientID, value.ids)),
                takeUntilDestroyed(this._destroyRef),
            ).subscribe(done => {
            if (done) {
                this.filterChange.next(this.filterChange.value);
                this.selection.clear();
            }
        });
    }

    select(row) {
        setTimeout(() => {
            this.patientService.hasSelectedPatients.set(this.selection.hasValue() && this.selection.selected.length > 1);
        })
    }
}

import {catchError, map, startWith, switchMap, tap} from 'rxjs/operators';
import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {PatientService} from '../patient.service';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import {
    CameraComponent,
    DeleteConfirmComponent,
    deleteItemFromArray,
    FileElement,
    FileService,
    getFileUrl,
    getReportStatus,
    getStatusColor,
    ReferringPhysicianAddComponent,
    ReferringPhysiciansSearchComponent,
    SharedService,
    ViewerSelectComponent,
} from '../../shared';
import {Location} from '@angular/common';
import {
    AddressDTO,
    ADMISSIONS,
    AppointmentDTO,
    DEBITERS,
    DefaultValues,
    FireOMI,
    Hl7IdName,
    InsuranceDTO,
    MedicalHistoryDTO,
    PatientDTO,
    PatientExamDTO,
    PatientFullDTO,
    PostalCode,
    VisitDTO,
} from '../../model';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {
    forkJoin,
    Observable,
    of as observableOf,
    of, filter
} from 'rxjs';

import {assign, reverse, set as _set, sortBy} from 'lodash';
import moment from 'moment';
import FileSaver from 'file-saver';
import $ from 'jquery';
import {ReportingService} from '../../reporting/reporting.service';
import {v4} from 'uuid';
import {PathologyEditComponent} from '../../setting/pathology-setting/pathology-edit/pathology-edit.component';
import {AppConfigService} from '../../app-config.service';
import {PROFILE_PLACEHOLDER} from '../../global-variables';
import {SchedulerService} from '../../scheduler/scheduler.service';
import {AppointmentEditComponent} from '../../scheduler/appointment-edit/appointment-edit.component';
import {EventEditComponent} from '../../scheduler/event-edit/event-edit.component';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {ExamSchedulerComponent} from '../../scheduler/exam-scheduler/exam-scheduler.component';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
    selector: 'ft-patient-folder',
    templateUrl: './patient-folder.component.html',
    styleUrls: ['./patient-folder.component.scss'],
})
export class PatientFolderComponent implements OnInit {
    patient: PatientDTO;
    patientFull: PatientFullDTO;
    genders: any[];
    maritalStatuses: any[];
    titles: any[];
    reports: any[] = [];
    confidentialities: any[] = [];
    patientClasses: any[];

    patientForm: FormGroup;
    insuranceForm: FormGroup;
    medicalForm: FormGroup;
    addressForm: FormGroup;

    patientClassesAbv: any = {
        I: 'Inpatient',
        O: 'Outpatient',
        B: 'Obstetrics',
        U: 'Unknown',
        P: 'Preadmit',
        R: 'Recurring patient',
        N: 'Not Applicable',
        C: 'Commercial Account',
    };

    appointments: AppointmentDTO[];
    visits: VisitDTO[];
    debiters: string[];
    admissions: string[];
    allergies: any[];
    scheduledProcedures: PatientExamDTO[];
    organisms: Hl7IdName[] = [];
    conventions: any[];

    profile: any;
    patientPhotoUrl: SafeResourceUrl = PROFILE_PLACEHOLDER;

    @Input() patID: number;
    patientId: number;

    patientExams: PatientExamDTO[];

    ageForm: FormGroup;
    age: number;

    private confidentiality: any;
    private readonly dateFormat: string;
    private readonly momentDatetimeFormat: string;

    fileElements: Observable<FileElement[]>;
    currentRoot: FileElement;
    currentPath: string;
    canNavigateUp = false;
    patientID: string;
    // Attached documents
    referringPhysicianControl = new FormControl('');
    filteredReferringPhysicians: any[] = [];
    filteredPostalCodes: PostalCode[] = [];
    cityControl = new FormControl('');
    postalCodeControl = new FormControl('');
    filteredOrganisms: Observable<Hl7IdName[]>;

    defaultValues: DefaultValues;
    private datasets: string =
        'genders,titles,maritalStatuses,confidentialities,patientClasses,allergies,defaultValues';
    imagingOrders: FireOMI[] = [];

    private _destroyRef = inject(DestroyRef);

    constructor(
        private route: ActivatedRoute,
        private location: Location,
        private _config: AppConfigService,
        private sharedService: SharedService,
        private scheduleService: SchedulerService,
        private _service: PatientService,
        private fileService: FileService,
        private reportingService: ReportingService,
        private dialog: MatDialog,
        private router: Router,
        private snackBar: MatSnackBar,
        private sanitizer: DomSanitizer,
        private fb: FormBuilder
    ) {

        this._destroyRef.onDestroy(() => this._service.patientSelected.next(null));

        this.profile = JSON.parse(localStorage.getItem('profile'));
        this.dateFormat = this._config.dateFormat;
        this.momentDatetimeFormat = this._config.momentDateTimeFormat;
        this.debiters = DEBITERS;
        this.admissions = ADMISSIONS;
        this.createForm();
        this.createAgeForm();
        this.changeAge();


        this.sharedService
            .getOrganismsList()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(value => (this.organisms = value));

        this.referringPhysicianControl.valueChanges
            .pipe(
                switchMap(() => {
                    const query = this.referringPhysicianControl.value;
                    return this.sharedService.queryReferringPhysicians(
                        10,
                        0,
                        'lastName',
                        'asc',
                        query
                    );
                }),
                map(data => data['content']),
                catchError(() => {
                    return observableOf([]);
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(data => (this.filteredReferringPhysicians = data));

        this.referringPhysicianControl.patchValue('');
    }

    isEditable(sps: any): boolean {
        return (
            this.profile &&
            this.profile.updateOrder &&
            sps.scheduledProcedureStepStatus === 'SCHEDULED'
        );
    }

    isFuture(sps: any): boolean {
        return moment(sps.scheduledProcedureStepStartDate).isAfter(
            moment().startOf('day')
        );
    }

    calculateIMCAndSC() {
        const _w = this.patientForm.get('weight').value;
        const _h = this.patientForm.get('height').value / 100;

        const imc = parseFloat((_w / (_h * _h)).toFixed(2));
        this.patientForm.get('imc').patchValue(imc);

        const sc = parseFloat((Math.sqrt(_h * _w) / 6).toFixed(2));
        this.patientForm.get('sc').patchValue(sc);
    }

    createNewAppointment() {
        this.dialog
            .open(AppointmentEditComponent, {
                data: {patient: this.patient},
            })
            .afterClosed()
            .pipe(
                filter(value => !!value),
                switchMap(() => this.scheduleService.getPatientAppointmentDTOs(this.patientId)),
                takeUntilDestroyed(this._destroyRef))
            .subscribe(appointments => {
                this.appointments = appointments;

                this.snackBar.open(
                    'Ordre créé avec succès!',
                    'OK',
                    {
                        duration: 2000,
                    }
                );
            });
    }

    changeDate() {
        const {years, months} = this.ageForm.getRawValue();

        const dateOfBirth = moment()
            .subtract(years, 'years')
            .subtract(months, 'months');

        this.patientForm.get('dateOfBirth').patchValue(dateOfBirth);
    }

    changeAge(): void {
        const value = moment(
            this.patientForm.get('dateOfBirth').value || moment()
        );

        const diffDuration = moment.duration(moment().diff(value));
        const years = diffDuration.years();
        const months = diffDuration.months();
        const days = diffDuration.days();

        this.ageForm.patchValue({years, months, days});
    }

    onSelectOrganism() {
        const organismName = this.insuranceForm.get('organismName')?.value;
        if (!organismName) return;

        const org = this.organisms.find(
            it => it.name.trim() === organismName.trim()
        );

        if (org) {
            this.insuranceForm.get('organismId').patchValue(org.id);

            this.findConventions(org.id);
        }

    }

    scheduleNewOrder() {
        this.dialog
            .open(ExamSchedulerComponent, {
                data: {
                    patient: this.patientFull,
                    isr: null,
                    selectedDateRange: {
                        start: moment(),
                        end: moment().add(15, 'h'),
                    },
                    editable: true,
                    queryParam: null,
                    panelClass: 'exam-dialog',
                },
                disableClose: true,
            })
            .afterClosed()
            .pipe(
                filter(value => !!value),
                switchMap(() => this.scheduleService.getPatientExams(this.patientId)),
                takeUntilDestroyed(this._destroyRef),
            ).subscribe(value =>
            (this.scheduledProcedures = value.filter(
                it => it.examStarted === null
            )));
    }

    editImage() {
        this.dialog
            .open(CameraComponent)
            .afterClosed()
            .pipe(
                filter(value => !!value),
                map(imageFile => {
                    const fileUUID = v4();

                    const progress = this.fileService.uploadFiles(
                        imageFile,
                        this.patientID,
                        'root',
                        fileUUID,
                        true
                    );

                    const allProgressObservables = [];
                    for (const key in progress)
                        allProgressObservables.push(progress[key]['progress']);

                    return {allProgressObservables, fileUUID};
                }),
                switchMap(value => forkJoin(value.allProgressObservables).pipe(map((data) => ({
                    data,
                    fileUUID: value.fileUUID
                })))),
                takeUntilDestroyed(this._destroyRef),
            ).subscribe(data => {
            this.updateFileElementQuery();
            this.fileElements.subscribe(
                el =>
                    (this.patientPhotoUrl = getFileUrl(
                        el.find(file => file.uuid === data.fileUUID)
                    ))
            );
            this.patientForm
                .get('patientPhotoUUID')
                .patchValue(data.fileUUID);
        });
    }

    ngOnInit() {
        this.sharedService.getDatasets(this.datasets)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(data => {
                this.datasets.split(',').forEach(it => (this[it] = data[it]));

                this.filteredOrganisms = this.insuranceForm
                    .get('organismName')
                    .valueChanges.pipe(
                        map(value => this._filter(value))
                    );

                this.patientForm.controls['externalPatientID'].disable();
                this.patientForm.controls['imc'].disable();
                this.patientForm.controls['sc'].disable();

                let id: number;

                this.route.params
                    .pipe(
                        switchMap((params: Params) => {
                            id = +params['id'] || this.patID;
                            this.patientId = id;
                            return id
                                ? this._service.getPatientFull(id)
                                : of({} as PatientFullDTO);
                        })
                    )
                    .subscribe(patient =>
                        this.setPatientDetails(this.patientId, patient)
                    );

                this.patientForm
                    .get('confidentialityId')
                    .valueChanges.subscribe(confidentialityId => {
                    this.confidentiality = this.confidentialities.find(
                        c => c.id === confidentialityId
                    );
                });


                this.cityControl.valueChanges
                    .pipe(
                        startWith(''),
                        switchMap(query =>
                            this.sharedService.getPaginatedPostalCodes(
                                10,
                                0,
                                'code',
                                'asc',
                                query
                            )
                        ),
                        map(data => data['content']),
                        catchError(() => observableOf([]))
                    )
                    .subscribe(data => (this.filteredPostalCodes = data));

                this.cityControl.patchValue('');


                this.postalCodeControl.valueChanges
                    .pipe(
                        switchMap(query =>
                            this.sharedService.getPaginatedPostalCodes(
                                10,
                                0,
                                'code',
                                'asc',
                                query
                            )
                        ),
                        map(data => data['content']),
                        catchError(() => observableOf([]))
                    )
                    .subscribe(data => (this.filteredPostalCodes = data));
                this.postalCodeControl.patchValue('');
            });
    }

    private _filter(value: string): Hl7IdName[] {
        const filterValue = value ? value.toLowerCase() : '';

        return this.organisms.filter(it =>
            (it.name + it.code).toLowerCase().includes(filterValue)
        );
    }

    addAllergy(event) {
        event.stopPropagation();
        this.dialog
            .open(PathologyEditComponent, {
                data: {type: 'external', icon: 'plus', title: 'NEW_ALLERGY'},
                disableClose: true,
            })
            .afterClosed()
            .pipe(
                filter(value => !!value),
                switchMap(data => this.sharedService.createAllergy(data)),
                takeUntilDestroyed(this._destroyRef),
            ).subscribe((res) => {
            this.allergies.push(res);
            const allergies =
                this.medicalForm.get('allergies').value;
            allergies.push(res.value);
            this.medicalForm.get('allergies').patchValue(allergies);
        });
    }

    savePatient(cb?: () => void): void {
        const patientData = this.patientForm.getRawValue();
        const patientAddress = this.addressForm.getRawValue();

        const medicalHistory = this.medicalForm.getRawValue();
        medicalHistory.allergies = medicalHistory.allergies?.join(',');

        const insurance = this.insuranceForm.getRawValue();
        const patientFull: PatientFullDTO = {
            patient: patientData,
            patientAddress,
            medicalHistory,
            insurance,
        };

        const saveMethod =
            !!this.patientId && this.patientId !== 0
                ? 'updatePatientFull'
                : 'savePatientFull';

        this._service[saveMethod](patientFull)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(data => {
                const {patient} = data;
                this.setPatientDetails(this.patientId, data);
                this._service.patientSelected.next(
                    [patient.lastName, patient.firstName].join(' ')
                );
                this.snackBar.open(
                    'Modifications enregistrées avec succès!',
                    'Ok',
                    {duration: 2000}
                );

                if (cb) cb();
            });
    }

    goBack(): void {
        this._service.patientSelected.next(null);
        this.location.back();
    }

    savePatientAndBack(): void {
        this.savePatient(() => this.goBack());
    }

    editSPS(sps) {
        this.dialog
            .open(EventEditComponent, {
                data: {
                    spsId: sps.id,
                    rpId: sps.requestedProcedureId,
                },
            })
            .afterClosed()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(res => {
                if (res) {
                    deleteItemFromArray(this.scheduledProcedures, sps);
                    this.scheduledProcedures.push(res);
                }
            });
    }

    onScheduleAppointment(apt: AppointmentDTO) {
        this.dialog
            .open(ExamSchedulerComponent, {
                data: {
                    patient: this.patientFull,
                    isr: apt,
                    selectedDateRange: {
                        start: moment(),
                        end: moment().add(15, 'h'),
                    },
                    editable: true,
                    queryParam: null,
                    panelClass: 'exam-dialog',
                },
                disableClose: true,
            })
            .afterClosed()
            .pipe(
                filter(value => (value && !Object.prototype.hasOwnProperty.call(value, 'isrId'))),
                tap(() => deleteItemFromArray(this.appointments, apt)),
                switchMap(() => this.scheduleService.getPatientExams(this.patientId)),
                takeUntilDestroyed(this._destroyRef))
            .subscribe(value =>
                (this.scheduledProcedures = reverse(
                    sortBy(
                        value.filter(
                            it => it.examStarted === null
                        ),
                        'scheduledProcedureStartDate'
                    )
                )));
    }

    onDeleteAppointments(apt: any) {
        this.dialog
            .open(DeleteConfirmComponent)
            .afterClosed()
            .pipe(
                filter(value => !!value),
                tap(() => deleteItemFromArray(this.appointments, apt)),
                switchMap(() => this.scheduleService.deleteAppointment(apt.id)),
                takeUntilDestroyed(this._destroyRef)
            ).subscribe();
    }

    deleteSPS(sps: any) {
        this.dialog
            .open(DeleteConfirmComponent)
            .afterClosed()
            .pipe(
                filter(value => !!value),
                switchMap(() => this.scheduleService.deleteEvent(sps.id)),
                takeUntilDestroyed(this._destroyRef)
            ).subscribe(() => deleteItemFromArray(this.scheduledProcedures, sps));
    }

    printRDV(sps: any) {
        this.scheduleService.printRDV(sps.id).pipe(takeUntilDestroyed(this._destroyRef)).subscribe(ok => {
            if (ok) {
                this.showPreview(
                    `/templates/rdv.html?v=${moment().format('YYYYMMDDHHmmssSSS')}`
                );
            } else console.log("Can't print rdv");
        });
    }

    showPreview(url) {
        const $iframe = $('<iframe />')
            .attr('src', url)
            .css({position: 'absolute', top: '-9999px'});
        $iframe.on('load', () => setTimeout(() => $iframe.remove(), 0));
        $(document.body).append($iframe);
    }

    canPrint(row: any): boolean {
        const status = this.getReportStatus(row.reportStatus);
        return status === 'VALIDATED' || status === 'SIGNED';
    }

    downloadReport(row) {
        this.reportingService
            .printReportingTask(row.reportingTaskId)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(res => {
                const mediaType = 'application/pdf';
                const blob = new Blob([res], {type: mediaType});
                const filename =
                    'Report_' + moment().format('YYYYMMDDHHmmss') + '.pdf';
                FileSaver.saveAs(blob, filename);
            });
    }

    openReportingTask(row) {
        this.router.navigate([
            '/reporting/report-edition',
            row.reportingTaskId,
        ]);
    }

    openInOsirix(row) {
        this.dialog
            .open(ViewerSelectComponent, {width: '400px'})
            .afterClosed()
            .pipe(
                filter(value => !!value),
                switchMap(res => this.reportingService.openOsirix(row.studyInstanceUID, res.aETitle)),
                takeUntilDestroyed(this._destroyRef))
            .subscribe(res => {
                if (!res)
                    this.snackBar.open(
                        'Les images ne sont pas en ligne',
                        'Ok',
                        {duration: 2000}
                    );
            });
    }

    openInViewer(studyInstanceUID: string) {
        this.reportingService.openWeasis(studyInstanceUID).pipe(takeUntilDestroyed(this._destroyRef)).subscribe();
    }

    getColor(status: string = '_'): string {
        return getStatusColor(status);
    }

    getReportStatus(status: string): string {
        return getReportStatus(status);
    }

    removeElement(element: FileElement) {
        this.fileService.deleteFile(element).pipe(takeUntilDestroyed(this._destroyRef)).subscribe(ok => {
            if (ok) this.updateFileElementQuery();
        });
    }

    createForm() {
        this.patientForm = this.fb.group(
            assign(new PatientDTO(), {
                firstName: ['', Validators.required],
                lastName: ['', Validators.required],
                email: ['', Validators.email],
                phone: [
                    '',
                    [
                        Validators.maxLength(16),
                        Validators.pattern(new RegExp('\\d')),
                    ],
                ],
                cin: ['', Validators.maxLength(12)],
                nationalNumber: ['', Validators.maxLength(20)],
            })
        );

        this.medicalForm = this.fb.group(new MedicalHistoryDTO());
        this.addressForm = this.fb.group(new AddressDTO());
        this.insuranceForm = this.fb.group(new InsuranceDTO());
    }

    addReferringPhysician() {

        this.dialog
            .open(ReferringPhysicianAddComponent)
            .afterClosed()
            .pipe(
                filter(value => !!value),
                takeUntilDestroyed(this._destroyRef),
            ).subscribe(physician => {
                if (physician) {
                    this.filteredReferringPhysicians.push(physician);
                    this.referringPhysicianControl.patchValue(
                        physician.fullName
                    );
                    this.patientForm
                        .get('consultingDoctorId')
                        .patchValue(physician.id);
                }

        });
    }

    onChangeRefPhy(event) {
        const physician = event.option.value;
        this.patientForm.get('consultingDoctorId').patchValue(physician?.id);
        this.referringPhysicianControl.patchValue(physician.fullName);
    }

    compareConvention(c1: string, c2: string): boolean {
        return c1 && c2 ? c1.split('@')[0] === c2.split('@')[0] : c1 === c2;
    }

    addFolder(folder: { name: string }) {
        const file: FileElement = {
            folder: true,
            patientID: this.patientID,
            name: folder.name,
            parent: this.currentRoot ? this.currentRoot.uuid : 'root',
        };

        this.fileService.createFile(file).pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
            this.updateFileElementQuery();
        });
    }

    afterUpload(event) {
        if (event) this.updateFileElementQuery();
    }

    moveElement(event: { element: FileElement; moveTo: FileElement }) {
        const elt = event.element;
        _set(elt, 'parent', event.moveTo.uuid);

        this.fileService.updateFile(elt).pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
            this.updateFileElementQuery();
        });
    }

    renameElement(element: FileElement) {
        _set(element, 'name', element.name);

        this.fileService.updateFile(element).pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
            this.updateFileElementQuery();
        });
    }

    updateFileElementQuery() {
        this.fileElements = this.fileService.getPatientDocuments(
            this.patientID,
            this.currentRoot ? this.currentRoot.uuid : 'root'
        );
    }

    private findConventions(organismId: any) {

        this.sharedService
            .getOrganismConventions(organismId)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(res => {
                if (res) this.conventions = res.map(it => it.split('@')[0]);
            });
    }

    navigateUp(): void {
        if (this.currentRoot && this.currentRoot.parent === 'root') {
            this.currentRoot = null;
            this.canNavigateUp = false;
            this.updateFileElementQuery();
        } else {
            this.currentRoot = this.fileService.get(this.currentRoot.parent);
            this.updateFileElementQuery();
        }
        this.currentPath = this.popFromPath(this.currentPath);
    }

    navigateToFolder(element: FileElement) {
        this.currentRoot = element;
        this.updateFileElementQuery();
        this.currentPath = this.pushToPath(this.currentPath, element.name);
        this.canNavigateUp = true;
    }

    pushToPath(path: string, folderName: string) {
        let p = path ? path : '';
        p += `${folderName}/`;
        return p;
    }

    popFromPath(path: string) {
        let p = path ? path : '';
        const split = p.split('/');
        split.splice(split.length - 2, 1);
        p = split.join('/');
        return p;
    }

    private createAgeForm() {
        this.ageForm = this.fb.group({years: '', months: '', days: ''});
    }

    private setPatientDetails(id: number, patientFull: PatientFullDTO) {
        this.patientFull = patientFull;

        let {patient, medicalHistory, patientAddress, insurance} =
            patientFull;

        if (!patient) patient = new PatientDTO();
        if (!medicalHistory) medicalHistory = new MedicalHistoryDTO();
        if (!patientAddress) patientAddress = new AddressDTO();
        if (!insurance) insurance = new InsuranceDTO();

        this._service.patientSelected.next(
            [patient.lastName, patient.firstName].join(' ')
        );
        this.patient = patient;
        this.patientID = patient.externalPatientID;

        this.patientForm.patchValue(
            assign(patient, {
                phone: patient.phone?.replaceAll('.', ''),
            })
        );

        if (patient.consultingDoctorId)
            this.sharedService
                .getReferringPhysicianById(patient.consultingDoctorId)
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe(doctor => {
                    if (doctor)
                        this.referringPhysicianControl.patchValue(
                            doctor.fullName
                        );
                });

        medicalHistory.allergies = medicalHistory.allergies?.split(',');

        this.medicalForm.patchValue(medicalHistory);


        this.addressForm.patchValue(patientAddress);
        this.cityControl.patchValue(patientAddress?.city);
        this.postalCodeControl.patchValue(patientAddress?.postalCode);

        const dob = moment(this.patientForm.get('dateOfBirth').value);
        this.age = moment.duration(moment().diff(dob)).years();

        setTimeout(() => {
            this.insuranceForm.patchValue(insurance);
            this.onSelectOrganism();
        }, 400);

        if (id) {
            forkJoin([
                this.scheduleService.getPatientAppointmentDTOs(id),
                this.scheduleService.getPatientExams(id),
                this.scheduleService.getPatientVisits(id),
                this.scheduleService.getPatientImagingOrders(
                    patient.externalPatientID
                ),
            ])
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe(data => {
                    this.appointments = data[0];
                    const _exams = data[1];
                    this.visits = data[2];
                    this.imagingOrders = data[3];
                    this.scheduledProcedures = reverse(
                        sortBy(
                            _exams.filter(it => it.examStarted === null),
                            'scheduledProcedureStartDate'
                        )
                    );
                    this.patientExams =
                        _exams /*.filter(it => it.examStarted !== null)*/ as PatientExamDTO[];
                });
        } else {
            const patientClass = this.patientClasses.find(
                it => it.value === this.defaultValues.defaultPatientClass
            );
            const maritalStatus = this.maritalStatuses.find(
                it => it.value === this.defaultValues.defaultMaritalStatus
            );
            const gender = this.genders.find(
                it => it.value === this.defaultValues.defaultGender
            );
            const title = this.titles.find(
                it => it.value === this.defaultValues.defaultTitle
            );
            const country = this.defaultValues.defaultCountry;
            const city = this.defaultValues.defaultCity;
            const confidentiality = this.confidentialities.find(
                it => it.value === this.defaultValues.defaultConfidentiality
            );
            const debiter = DEBITERS.find(
                it => it === this.defaultValues.defaultPaymentModality
            );
            const relationWithInsured = ['HIMSELF', 'PARTNER', 'CHILD'].find(
                it => it === this.defaultValues.defaultRelationWithInsured
            );

            this.patientForm.patchValue({
                titleId: title?.id,
                patientClassId: patientClass?.id,
                debiter,
            });
            this.insuranceForm
                .get('relationWithInsured')
                .patchValue(relationWithInsured);

            this.addressForm.patchValue({city, country});
            setTimeout(() => this.cityControl.patchValue(city));

            this.patientForm.patchValue({
                confidentialityId: confidentiality?.id,
                maritalStatusId: maritalStatus?.id,
                genderId: gender?.id,
            });
        }

        this.fileService
            .getPatientDocuments(this.patientID, 'root')
            .subscribe(files => (this.fileElements = of(files)));
        if (patient.imageBase64)
            this.patientPhotoUrl =
                this.sanitizer.bypassSecurityTrustResourceUrl(
                    patient.imageBase64 || PROFILE_PLACEHOLDER
                );

        this.changeAge();
    }

    findReferringPhysician() {
        this.dialog
            .open(ReferringPhysiciansSearchComponent, {
                minWidth: '760px',
                minHeight: '60vh',
                disableClose: true,
            })
            .afterClosed()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(physician => {
                if (physician) {
                    this.filteredReferringPhysicians.push(physician);
                    this.referringPhysicianControl.patchValue(
                        physician.fullName
                    );
                    this.patientForm
                        .get('consultingDoctorId')
                        .patchValue(physician?.id);
                }
            });
    }

    onChangePostalCode(event: MatAutocompleteSelectedEvent) {
        const postalCode = event.option.value;

        this.addressForm.get('city').patchValue(postalCode.location);
        this.addressForm.get('postalCode').patchValue(postalCode.code);
        this.cityControl.patchValue(postalCode.location);
        this.postalCodeControl.patchValue(postalCode.code);
    }

    scheduleImagingOrder(order: FireOMI) {
        this.scheduleService.scheduleOrder(order).subscribe(value => {
            this.snackBar.open('Order scheduled', '', {duration: 2000});
            this.scheduleService
                .getPatientImagingOrders(this.patientID)
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe(value1 => (this.imagingOrders = value1));
        });
    }

    editImagingOrder(order: FireOMI) {
        this.scheduleService.changeProcedure(order)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(() => {
                this.snackBar.open('Changed', '', {duration: 2000});
            });
    }

    splitter(str: string, idx1: number, idx2: number): string {
        const split = str.split('__');
        return split[idx1] + ' ' + split[idx2];
    }
}
